import { getRedirectCoverage, IProduct, ITechs } from '@vfit/consumer/data-access';
import { checkWindow } from '@vfit/shared/data-access';

export enum Placeholders {
  TECHNOLOGY = '$TECHNOLOGY',
  ADDRESS = '$ADDRESS',
  PRICE = '$PRICE',
  ORIGINAL_PRICE = '$ORIGINAL_PRICE',
  MAX_DOWNLOAD_SPEED = '$MAX_DOWNLOAD_SPEED',
}

export const evaluateOfferDetails = (
  productsAll?: IProduct[],
  cmsId?: string,
  isPanicMode?: boolean
) => {
  if (!productsAll || productsAll?.length === 0 || !cmsId || cmsId === '') return null;
  const product = productsAll.find((p) => p?.cmsId === cmsId);
  return {
    price: product?.price || '',
    originalPrice: product?.originalPrice || '',
    recurrence: product?.recurrence || '',
    offerName: product?.category || '',
    offerDescription: product?.description || '',
    urlClickToCall: isPanicMode ? product.panicModeAction?.url || '' : product?.redirectR02 || '',
    redirectUrl: getRedirectCoverage() || '',
  };
};

export const getTechByKey = (techs: ITechs, key: string) =>
  techs?.[key.toLowerCase()] || techs?.[key.toUpperCase()];

export const getFormattedAddress = (address: string) => {
  const formattedAddress = address
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return handleRomanNumber(formattedAddress);
};

export const getRedirectUrl = (
  basePath: string,
  placeId: string,
  offerId: number,
  cmsId: string,
  isLockin: boolean
) => {
  let url: string;
  if (isLockin) {
    url = checkWindow() ? `${window.location.href}?act=${cmsId}` : '';
  } else {
    url = `${basePath}?placeId=${placeId}&offerId=${offerId}&flowType=1&icmp=cbu_web_returning-visitor`;
  }
  return url;
};
export const getRedirectUrlFromHomePage = (
  basePath: string,
  placeId: string,
  cmsId: string,
  hubPath: string,
  isLockin: boolean
) => {
  let url: string;
  if (isLockin) {
    url = `${hubPath}?act=${cmsId}`;
  } else {
    url = `${basePath}?placeId=${placeId}&cmsId=${cmsId}&flowType=1&icmp=cbu_web_returning-visitor`;
  }
  return url;
};

export const handleRomanNumber = (str: string) => {
  const romanNumeralRegex = /\b(M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3}))\b/gi;
  return str.replace(romanNumeralRegex, (match) => match.toUpperCase());
};
